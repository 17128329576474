import React from "react"
import styled, { keyframes } from "styled-components"

function AdviceAnimation(props) {
    return <Wrapper>Random Advice: {props.advice}</Wrapper>
}

const animation = keyframes`
    0% { opacity: 0; }
    25% { opacity: 1; }
    75% { opacity: 1; }
    100% { opacity: 1; }
`

const Wrapper = styled.span`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 30s;
    animation-fill-mode: forwards;
`

export default AdviceAnimation
import React, { useState, useEffect } from "react"
import AdviceAnimation from "./components/AdviceAnimation"
import "./App.css";

function App() {
  const [showAdvice, setShowAdvice] = useState(false)
  const [advice, setAdvice] = useState({})

  function getAdvice() {
    fetch("https://api.adviceslip.com/advice")
      .then(res => res.json())
      .then(data => setAdvice(data.slip.advice))
  }

  useEffect(() => {
    getAdvice()

    setTimeout(() => {
      setShowAdvice(true)
    }, 5000)
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <title>Breathing Exercise</title>
      </header>
      <main className="App-body">
        <h1>Just Breathe</h1>
        <div className="breath-frame">
          <div className="breath-frame__text breath-frame__text--upper">Breathe In</div>
          <div className="breath-frame__text breath-frame__text--right">Hold</div>
          <div className="breath-frame__text breath-frame__text--bottom">Breathe Out</div>
          <div className="breath-frame__text breath-frame__text--left">Hold</div>
          <div className="breath-frame__box">
            <div className="breath-frame__dot"></div>
            <img className="breath-frame__lung"
              src="/images/lungs.svg" width="360" alt="Lung" />
          </div>
        </div>
        <div className="advice-container">
          {showAdvice &&
            <p onClick={() => getAdvice()} style={{ cursor: "pointer" }}>
              <AdviceAnimation advice={advice} />
            </p>}
        </div>
      </main>
    </div>
  );
}

export default App;
